import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Ink from "react-ink";
import DelayLink from "../../helpers/delayLink";
import { Link } from "react-router-dom";
import { logoutUser } from "../../../services/user/actions";
import { getCategory } from "../../../services/items/actions";

class Nav extends Component {
  state = {
    searchtext: "",
    showHideClass: "",
  };

  //
  constructor(props) {
    super(props);
    // Initialize state to manage the visibility of both dropdowns
    this.state = {
      showMoreDropdown: false,
    };
  }

  // Function to toggle the Products dropdown visibility
  toggleProductsDropdown = () => {
    this.setState((prevState) => ({
      showMoreDropdown: false, // Close the "More" dropdown when "Products" is clicked
    }));
  };

  // Function to toggle the More dropdown visibility
  toggleMoreDropdown = () => {
    this.setState((prevState) => ({
      showMoreDropdown: !prevState.showMoreDropdown,
    }));
  };

  dropDownShowHide = () => {
    if (this.state.showHideClass === "show") {
      this.setState({ showHideClass: "" });
    } else {
      this.setState({ showHideClass: "show" });
    }
  };
  toggleIcon = () => {
    if (this.state.toggleClass === "open") {
      this.setState({ toggleClass: "" });
    } else {
      this.setState({ toggleClass: "open" });
    }
  };
  searchShowHide = () => {
    if (this.state.showHidesearch === "show") {
      this.setState({ showHidesearch: "" });
    } else {
      this.setState({ showHidesearch: "show" });
    }
  };
  static contextTypes = {
    router: () => null,
  };

  handleSearch = () => {
    console.log(this.state.searchtext);
    if (this.state.searchtext.length >= 2) {
      this.props.history.push({
        pathname: "/explore", // search: '?query='+this.state.searchtext,
        state: { detail: this.state.searchtext },
      });
    }
  };
  handleInput = (event) => {
    // console.log(event.target);
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  componentDidMount() {
    // console.log(this.props.location.state)
    if (this.props.active_nearme === true) {
      this.setState({ active_nearme: true });
    }
    if (this.props.active_explore === true) {
      this.setState({ active_explore: true });
    }
    if (this.props.active_cart === true) {
      this.setState({ active_cart: true });
    }
    if (this.props.active_account === true) {
      this.setState({ active_account: true });
    }
    if (this.props.active_alerts === true) {
      this.setState({ active_alerts: true });
    }

    this.props.getCategory();
  }

  render() {
    const { pathname } = this.props.location;
    const isMoreActive = pathname === "/more";

    const { cartTotal, get_category, user } = this.props;
    return (
      <React.Fragment>
        <header className="desktop-header">
          <nav
            className="navbar navbar-expand-lg navbar-light secondary-navbar"
            style={{ display: "none" }}
          >
            <div className="container-fluid">
              <div
                className="navbar-collapse"
                id="navbarSupportedContent-secondary"
              >
                <ul className="navbar-nav mr-auto navbar-left-menu spl-links">
                  <li>
                    {" "}
                    <a href="tel:+6563454565">
                      {" "}
                      <span className="mob-icon">
                        <i className="bi bi-telephone" />
                      </span>
                      <span className="desk-icon">
                        <span className="link-lable">Call us:</span>(65)
                        63454565
                      </span>
                    </a>{" "}
                  </li>
                  <li>
                    {" "}
                    <a href="tel:+6596274977">
                      {" "}
                      <span className="mob-icon">
                        <i className="fa fa-whatsapp" />
                      </span>
                      <span className="desk-icon">
                        <span className="link-lable">Whatsapp Us:</span>(65)
                        96274977
                      </span>
                    </a>
                  </li>
                  {/*<li><a href="https://facebook.com/byspajelita"> <span className="mob-icon"><i className="bi bi-facebook"></i></span> <span className="desk-icon"><span className="link-lable">Visit Us On:</span> Facebook</span></a></li>
                   */}{" "}
                </ul>
              </div>
            </div>
          </nav>
          <nav className="navbar navbar-expand-lg navbar-light primary-navbar">
            <div className="container-fluid">
              <DelayLink className="navbar-brand" to="/">
                <img src="/assets/img/logos/logo.png" />
              </DelayLink>
              <button
                className="mob-search-btn"
                onClick={this.searchShowHide}
                type="button"
              >
                <i className="bi bi-search  menu-icon" />
              </button>
              <button
                className={"navbar-toggler " + this.state.toggleClass}
                onClick={this.toggleIcon}
                type="button"
                data-toggle="collapse"
                data-target="#main_menu"
                aria-controls="main_menu"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                {/* <span className="navbar-toggler-icon"></span> */}
                <i className="bi bi-list" />
              </button>
              <div className={"searchbar_section " + this.state.showHidesearch}>
                <div className="search_bar">
                  <input
                    type="text"
                    onChange={this.handleInput}
                    name="searchtext"
                    value={this.state.searchtext}
                    placeholder="Find Best Services & Products"
                  />
                  <button
                    type="submit"
                    value="Search"
                    onClick={this.handleSearch}
                  >
                    Search
                  </button>
                  <button
                    type="button"
                    value=""
                    className="searchclose_btn"
                    onClick={this.searchShowHide}
                  >
                    <i className="bi bi-x" />
                  </button>
                </div>
              </div>

              <div className="collapse navbar-collapse" id="main_menu">
                <ul className="navbar-nav ml-auto navbar-right-menu">
                  <li
                    className={
                      this.props.location.pathname === "/"
                        ? "nav-item active"
                        : "nav-item"
                    }
                  >
                    <NavLink className="nav-link" to="">
                      {" "}
                      Home
                    </NavLink>
                  </li>

                  <li
                    className={
                      this.props.location.pathname === "/services"
                        ? "nav-item active"
                        : "nav-item "
                    }
                  >
                    <NavLink className="nav-link" to="/services">
                      {" "}
                      Spa Services
                    </NavLink>
                  </li>

                  {/* <li  className={(this.props.location.pathname === '/your-salon') ? 'nav-item active' : 'nav-item '} >
				  <NavLink className="nav-link" to="/your-salon"> Crown by SJ </NavLink>
					</li> */}

                  <li
                    className={
                      this.props.location.pathname === "/products"
                        ? "nav-item dropdown active"
                        : "nav-item dropdown"
                    }
                    onClick={this.dropDownShowHide}
                  >
                    <a className="nav-link"> Product</a>
                    <ul className={"nav-dropdown " + this.state.showHideClass}>
                      <li>
                        <Link
                          className="nav-link"
                          to={{ pathname: "/products" }}
                        >
                          All Products
                        </Link>
                      </li>
                      {get_category.success == 1 ? (
                        <>
                          {get_category.cat.map((item, index) => (
                            <li key={index}>
                              <Link
                                className="nav-link"
                                to={{
                                  pathname: "/products/" + item.id,
                                  state: { cat_name: item.service_name },
                                }}
                              >
                                {item.service_name}
                              </Link>
                            </li>
                          ))}
                        </>
                      ) : (
                        ""
                      )}
                    </ul>
                  </li>

                  <li
                    className={
                      this.props.location.pathname === "/about"
                        ? "nav-item active"
                        : "nav-item"
                    }
                  >
                    <NavLink className="nav-link" to="/about">
                      {" "}
                      About Us
                    </NavLink>
                  </li>

                  <li
                    className={
                      this.props.location.pathname === "/contact-us"
                        ? "nav-item active"
                        : "nav-item"
                    }
                  >
                    <NavLink className="nav-link" to="/contact-us">
                      Contact Us
                    </NavLink>
                  </li>

                  {/* <li   className={(this.props.location.pathname === '/courses') ? 'nav-item active' : 'nav-item '} >
				  <NavLink className="nav-link" to="/courses"> Courses</NavLink>
				</li> */}
                  {/* <li   className={(this.props.location.pathname === '/facility') ? 'nav-item active' : 'nav-item '} >
				  <NavLink className="nav-link" to="/facility">Facilities</NavLink>
				</li> */}
                  {/* <li   className={(this.props.location.pathname === '/gallery') ? 'nav-item active' : 'nav-item '} >
				  <NavLink className="nav-link" to="/gallery"> Gallery</NavLink>
				</li> */}
                  {/* <li   className={(this.props.location.pathname === '/promotion') ? 'nav-item active' : 'nav-item '} >
				  <NavLink className="nav-link" to="/promotion"> Promotions</NavLink>
				</li> */}
                  {/* <li   className={(this.props.location.pathname === '/gift-voucher') ? 'nav-item active' : 'nav-item '} >
				  <NavLink className="nav-link" to="/gift-voucher"> Gift Voucher</NavLink>
				</li> */}

                  {/* <li className={(this.props.location.pathname === '/more') ? 'nav-item dropdown active' : 'nav-item dropdown'}   onClick={this.dropDownShowHide}>
				  <a className="nav-link"> More</a>
				  <ul className={'nav-dropdown ' + this.state.showHideClass}>
				  		<li>
							<Link className="nav-link"  to={{pathname: "/promotion"}} >
								Promotions
							</Link>
					  	</li>
					  	<li>
							<Link className="nav-link"  to={{pathname: "/gift-voucher"}} >
								Gift Voucher
							</Link>
					  	</li>
					  	<li>
							<Link className="nav-link"  to={{pathname: "/courses"}} >
								Courses
							</Link>
					 	</li>
					  	<li>
							<Link className="nav-link"  to={{pathname: "/gallery"}} >
								Gallery
							</Link>
					  	</li>
						<li>
							<Link className="nav-link"  to={{pathname: "/redemption"}} >
							Loyalty Point Redemption
							</Link>
					  	</li>
				  </ul>
				</li> */}

                  <li
                    className={`nav-item dropdown ${
                      isMoreActive ? "active" : ""
                    }`}
                    onClick={this.toggleMoreDropdown}
                  >
                    <a className="nav-link">More</a>
                    <ul
                      className={`nav-dropdown ${
                        this.state.showMoreDropdown ? "show" : ""
                      }`}
                    >
                      <li>
                        <Link
                          className="nav-link"
                          to={{ pathname: "/promotion" }}
                        >
                          Promotions
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="nav-link"
                          to={{ pathname: "/gift-voucher" }}
                        >
                          Gift Voucher
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="nav-link"
                          to={{ pathname: "/courses" }}
                        >
                          Courses
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="nav-link"
                          to={{ pathname: "/gallery" }}
                        >
                          Gallery
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="nav-link"
                          to={{ pathname: "/redemption" }}
                        >
                          Loyalty Point Redemption
                        </Link>
                      </li>
                    </ul>
                  </li>

				  <li
                    className={
                     "nav-item mobile-app"
                    }
                  >
                    <NavLink className="nav-link" to="/https://nicebeauty.sg/admin/NiceBeautyApp.apk">
                      {" "}
					  <img src="/assets/img/icons/android_app.png" />
                    </NavLink>
                  </li>

                  {/* <li  className={(this.props.location.pathname === '/redemption') ? 'nav-item active' : 'nav-item '} >
				  <NavLink className="nav-link" to="/redemption"> Loyalty Point Redemption</NavLink>
				</li> */}
                </ul>
                <ul className="navbar-nav ml-auto navbar-right-menu ">
                  <li className="nav-item d-none d-sm-block">
                    <NavLink className="nav-link" to="/cart">
                      {" "}
                      <i className="bi bi-basket  menu-icon" />
                      <span className="cart-quantity-badge">
                        {cartTotal.productQuantity}
                      </span>
                    </NavLink>
                  </li>

                  <li className="nav-item d-none d-sm-block">
                    <a className="nav-link" onClick={this.searchShowHide}>
                      {" "}
                      <i className="bi bi-search  menu-icon" />
                    </a>
                  </li>
                </ul>

                <ul className="navbar-nav navbar-right-menu log-reg">
                  {/* <li className="nav-item">
				  <NavLink className="nav-link" to="/cart"> <i className="bi bi-basket  menu-icon"></i>
						<span className="cart-quantity-badge">
							{cartTotal.productQuantity}
						</span>
					</NavLink>
				</li> */}
                  {this.props.user.success == 1 ? (
                    <li className="nav-item mini dropdown">
                      <NavLink to="/my-account" className="nav-link">
                        <i
                          className="fa fa-user-o   menu-icon"
                          aria-hidden="true"
                        />{" "}
                        {user.profile.firstname}
                      </NavLink>

                      <ul className="nav-dropdown">
                        <li>
                          {" "}
                          <NavLink to="/my-orders" className="nav-link">
                            <i
                              className="fa fa-user-o   menu-icon"
                              aria-hidden="true"
                            />{" "}
                            Your Orders
                          </NavLink>{" "}
                        </li>
                        <li>
                          {" "}
                          <NavLink to="/my-account" className="nav-link">
                            <i
                              className="fa fa-user-o   menu-icon"
                              aria-hidden="true"
                            />{" "}
                            Your Profile
                          </NavLink>{" "}
                        </li>
                        <li>
                          {" "}
                          <a
                            className="nav-link"
                            onClick={() =>
                              this.props.logoutUser(this.props.user)
                            }
                          >
                            {" "}
                            <i className="bi bi-person-circle  menu-icon" />{" "}
                            Logout{" "}
                          </a>{" "}
                        </li>
                      </ul>
                    </li>
                  ) : (
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/login">
                        {" "}
                        <i className="bi bi-person-circle  menu-icon" /> Login /
                        Register{" "}
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </nav>
        </header>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  get_category: state.items.get_category,
  cartTotal: state.total.data,
  user: state.user.user,
});

export default connect(
  mapStateToProps,
  { logoutUser, getCategory }
)(withRouter(Nav));
