import React, { Component } from "react";
import { Redirect } from "react-router";

import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import Ink from "react-ink";
import DelayLink from "../../helpers/delayLink";

class Footer extends Component {
  constructor() {
    super();
  }

  state = {
    active_home: false,
    active_products: false,
    active_explore: false,
    active_cart: false,
    active_account: false,
    active_alerts: false,
    unread_alerts_count: null,
    error: false,
    agree: false,
    email: "",
    shownewsletter: true,
  };

  componentDidMount() {
    if (this.props.active_home === true) {
      this.setState({ active_home: true });
    }
    if (this.props.active_products === true) {
      this.setState({ active_products: true });
    }
    if (this.props.active_explore === true) {
      this.setState({ active_explore: true });
    }
    if (this.props.active_cart === true) {
      this.setState({ active_cart: true });
    }
    if (this.props.active_account === true) {
      this.setState({ active_account: true });
    }
    if (this.props.active_alerts === true) {
      this.setState({ active_alerts: true });
    }
  }

  render() {
    const {
      cartTotal,
      alertUnreadTotal,
      newsletter_response,
      get_category,
      get_brand,
    } = this.props;

    return (
      <React.Fragment>
        <footer className="footer-holder">
          <div className="footer-top container">
            <img
              src="/assets/img/footer-sm-one.png"
              className="footside-img-one"
            />
            <img
              src="/assets/img/footer-sm-two.png"
              className="footside-img-two"
            />
            <div className="row  justify-content-md-center">
              <div className="col-lg-12 col-md-12 col-sm-2">
                <div className="footer-logo">
                  <img
                    src="/assets/img/logos/logo.png"
                    className="logo"
                    alt="logo"
                  />
                </div>
              </div>
              <div className="col-lg-5 col-md-4 col-sm-4">
                <h3>Address</h3>
                <ul className="footer-address">
                  <li>
                    {" "}
                    <span className="mob-icon">
                      <i className="bi bi-geo" />
                    </span>
                    <span className="link-lable"> </span>64A Serangoon Rd,
                    Singapore Singapore 217972
                  </li>
                  <li>
                    {" "}
                    <a href="tel:+6563454565">
                      {" "}
                      <span className="mob-icon">
                        <i className="bi bi-telephone" />
                      </span>
                      <span className="desk-icon">
                        <span className="link-lable">Call us:</span>+65 6292
                        6626
                      </span>
                    </a>{" "}
                  </li>
                  {/* <li> <a href="tel:+6596274977"> <span className="mob-icon"><i className="fa fa-whatsapp"></i></span><span className="desk-icon"><span className="link-lable">Whatsapp Us:</span>(65) 96274977</span></a></li> */}
                  <li>
                    {" "}
                    <a href="mailto:booking@nicebeauty.sg">
                      {" "}
                      <span className="mob-icon">
                        <i className="bi bi-envelope" />
                      </span>
                      <span className="desk-icon">
                        <span className="link-lable">Email:</span>
                        booking@nicebeauty.sg
                      </span>
                    </a>
                  </li>
                  {/* <li><a href="https://facebook.com/byspajelita" target="_blank"> <span className="mob-icon"><i className="bi bi-facebook"></i></span> <span className="desk-icon"><span className="link-lable">Visit Us On:</span> Facebook</span></a></li> */}
                </ul>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-3">
                <h3>Links</h3>
                <ul className="footer-menu">
                  {/* <li><DelayLink to="/home">Home</DelayLink></li> */}

                  <li>
                    <DelayLink to="/about">About Us</DelayLink>
                  </li>
                  <li>
                    <DelayLink to="/contact-us">Contact Us </DelayLink>
                  </li>
                  {/*	<li><DelayLink to="">Spa Treatment</DelayLink></li>
							<li><DelayLink to="">Shop</DelayLink></li> */}
                  <li>
                    <DelayLink to="/services">Services</DelayLink>
                  </li>
                  <li>
                    <DelayLink to="/products">Products</DelayLink>
                  </li>
                  {/* <li><DelayLink to="/privacy-policy">Privacy Policy</DelayLink></li>  */}
                  {/* <li><DelayLink to="/terms-condition">Terms and Conditions</DelayLink></li> */}
                </ul>
              </div>

              <div className="col-lg-4 col-md-4 col-sm-3">
                <h3>Follow Us</h3>
                <ul className="footer-media">
                  <li>
                    {" "}
                    <a href="http://facebook.com/nicebeautysg" target="_blank">
                      {" "}
                      <span className="mob-icon">
                        <i className="fa fa-facebook" />
                      </span>
                    </a>
                  </li>
                  <li>
                    {" "}
                    <a
                      href="https://www.instagram.com/nicebeautysg/"
                      target="_blank"
                    >
                      {" "}
                      <span className="mob-icon">
                        <i className="fa fa-instagram" />
                      </span>
                    </a>
                  </li>
                </ul>

				

				<div className="fac-box">
                  <a
                    href="https://nicebeauty.sg/admin/NiceBeautyApp.apk"
                    target="blank"
                  >
                    <div className="playstore-link">
                      <img src="/assets/img/icons/android_app.png" />
                    </div>
                  </a>
                </div>
              </div>

              <div className="col-lg-3 col-md-4 col-sm-3" style={{}}>
                {/* <div className="fac-box">
                  <a href="/help-center">
                    <div className="fac-icon">
                      <i className="bi bi-headset" />
                    </div>
                    <div className="facilty-title">Help Center</div>
                  </a>
                </div>

                <div className="fac-box">
                  <a href="/enquiry">
                    <div className="fac-icon">
                      <i className="bi bi-envelope" />
                    </div>
                    <div className="facilty-title">Message Us</div>
                  </a>
                </div> */}

                {/* <div className="fac-box">
						<a href="https://apps.apple.com/us/app/spajelita/id1618176703" target="blank">
						 
						  <div className="app-store-link">
							<img src="/assets/img/icons/app_store.png"/>
						  </div>
						  </a>
						</div> */}

               


              </div>
            </div>
          </div>
          <div className="footer-copyright" style={{ display: "none" }}>
            <p className="footer-copyright-txt">
              Copyright © 2024 nicebeauty.sg - All Rights Reserved
            </p>
          </div>

          <div className="d-block d-sm-none">
            <div className="content pt-10 font-size-xs clearfix footer-fixed">
              <NavLink to="/home" className="col footer-links">
                <i className="bi bi-house mob-menu-icon" /> <br />
                <span
                  className={this.state.active_home ? "active-footer-tab" : ""}
                >
                  {this.state.active_home ? (
                    localStorage.getItem("footerAlerts")
                  ) : (
                    <span className="  mob-menu-txt"> Home</span>
                  )}
                </span>
              </NavLink>

              <NavLink to="/services" className="col footer-links">
                <i className="bi bi-menu-button mob-menu-icon" /> <br />
                <span
                  className={
                    this.state.active_alerts ? "active-footer-tab" : ""
                  }
                >
                  {this.state.active_alerts ? (
                    localStorage.getItem("footerAlerts")
                  ) : (
                    <span className="  mob-menu-txt"> Service</span>
                  )}
                </span>
              </NavLink>
              <NavLink to="/products" className="col footer-links">
                <i className="bi bi-shop mob-menu-icon" /> <br />
                <span
                  className={
                    this.state.active_products ? "active-footer-tab" : ""
                  }
                >
                  {this.state.active_products ? (
                    localStorage.getItem("footerAlerts")
                  ) : (
                    <span className="  mob-menu-txt"> Shop</span>
                  )}
                </span>
              </NavLink>

              <NavLink to="/cart" className="col footer-links">
                <i className="bi bi-basket  mob-menu-icon" /> <br />
                <span
                  className={this.state.active_cart ? "active-footer-tab" : ""}
                >
                  {this.state.active_cart ? (
                    localStorage.getItem("footerCart")
                  ) : (
                    <span className="  mob-menu-txt"> Cart</span>
                  )}
                  <span
                    className="cart-quantity-badge"
                    style={{
                      backgroundColor: localStorage.getItem("storeColor"),
                    }}
                  >
                    {cartTotal.productQuantity}
                  </span>
                </span>
              </NavLink>
              <NavLink to="/my-account" className="col footer-links">
                <i className="bi bi-person  mob-menu-icon" /> <br />
                <span
                  className={
                    this.state.active_account ? "active-footer-tab" : ""
                  }
                >
                  {this.state.active_account ? (
                    <span className="  mob-menu-txt"> Login / Register</span>
                  ) : (
                    <span className="  mob-menu-txt"> My Account</span>
                  )}
                </span>
              </NavLink>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  cartTotal: state.total.data,
});

export default connect(
  mapStateToProps,
  {}
)(Footer);
